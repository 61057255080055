import React from 'react';
import classes from './css/Loader.module.css';


const Loader = () => {
    return (
        <div className={classes.loader}>
            
        </div>
    );
};

export default Loader;