import React from 'react';

const Project = () => {
    return (
        <div>
            <p>test</p>
        </div>
    );
};

export default Project;