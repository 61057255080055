import React from 'react';

const Footer = () => {
    return (
        <footer className='footer'>
            <p className='footer_copy'>&copy; Mikryukov Oleg 2022</p>
        </footer>
    );
};

export default Footer;